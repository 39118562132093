@media (max-width: 768px) {
    .carousel-container {
      position: relative;
      width: 100%;
      height: 40rem;
      height: auto; /* Adjust height dynamically based on content */
      overflow: hidden;
    }
    .carousel-caption {
      font-size: 80px; /* Decrease font size for smaller screens */
      padding-left: 10px; /* Adjust padding for smaller screens */
      padding-right: 10px; /* Adjust padding for smaller screens */
      width: 90%; /* Adjust width for smaller screens */
    }
  }
  
  @media (max-width: 640px) {
   .productpage{
    margin-top: 30px;
    /* height: 200px; */
   }
   .taulene , .productimg{
    height: 100px important;
   }
  }