.carousel-container {
  position: relative;
  width: 100%;
  height: 40rem;
  height: auto; /* Adjust height dynamically based on content */
  overflow: hidden;
}

.carousel-image-container {
  position: relative;
  width: 100%;
}

.carousel-image {
  width: 100%;
  height: 600px;
  object-fit: cover;
  transition: transform 10s ease-in-out;
}

.carousel-caption {
  
  position: absolute;
  top: 0rem;
  left: 1rem;
  padding-left: 20px; /* Adjust padding for smaller screens */
  padding-right: 20px; /* Adjust padding for smaller screens */
  color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: 50%; /* Adjust width for smaller screens */
  
}
.carousel-caption .image-capton{
  font-size: 9vh;
  line-height: 9vh;
}
@keyframes zoomIn {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

.carousel-image-container.active img {
  animation: zoomIn 10s ease-in-out forwards;
}

/* Media queries for responsiveness */

@media (max-width: 768px) {
  .carousel-container {
    position: relative;
    width: 100%;
    height: 40rem;
    height: auto; /* Adjust height dynamically based on content */
    overflow: hidden;
  }
  .carousel-caption {
    font-size: 20px; /* Decrease font size for smaller screens */
    padding-left: 10px; /* Adjust padding for smaller screens */
    padding-right: 10px; /* Adjust padding for smaller screens */
    width: 90%; /* Adjust width for smaller screens */
  }
}

@media (max-width: 640px) {
  .carousel-caption h1 {
    font-size: 20px; /* Further decrease font size for smaller screens */
    padding-left: 5px; /* Adjust padding for smaller screens */
    padding-right: 5px; /* Adjust padding for smaller screens */
  }
  .carousel-caption .image-capton{
    font-size: 40px;
    line-height: 40px;
  }
}
