.footer {
  padding: 42px;
  max-width: 100%;
  background-color:white;
  color: #070707;
  /* padding: 0rem 0rem; */
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 2.5vh;
  text-align: center;
  /* box-shadow:inset 0px 20px 10px rgb(12, 12, 12,0.1); */
  height: fit-content;
 
}
 
 
.footer .container {
  max-width: 1200px;
  margin: 0 auto;
}
 
.footer-content {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
}
 
.company-info,
.menu,
.social-links {
  width: 30%;
  margin-bottom: 2rem;
}
 
.footer-content .logo {
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
 
.footer-content .logo .logo-img {
  width: 200px;
  height: 170px;
  border-radius: 50%;
 
}
 
.menu {
  color: #070707;
}
 
.company-info p .address {
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 1.6em;
  letter-spacing: 0.6px;
}
 
.menu h4,
.company-info h4,
.social-links h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: chocolate;
}

.menu  ul li a{
 font-size:12px;
}
 
.company-info p .social-link {
  list-style: none;
  line-height: 2rem;
  margin: 0 auto;
  padding-left: 0;
  margin-top: 20px;
}



 
 
a {
  text-decoration: none;
}
 
a:hover {
  opacity: 0.5;
  text-decoration: none;
}
 
.fa-instagram {
  background-color: #fdfafa;
  border-radius: 25px;
  color: #d63a3a;
  font-size: 17px;
  transform: scale(1.2);
}
 
.indiamart {
  border-radius: 25px;
  color: #af4040;
  transform: scale(0.9);

  border: none !important;
  outline: none;
  width: 70%;
}
 
.footer-bottom {
  margin-top: -1rem;
  border-top: 1px solid #444;
  padding-top: 1rem;
  height: fit-content;
}
 
.footer-bottom p {
  margin: 0;
}
.company-info p .social-link {
  padding-left: 40%;
}
 
/* Responsive Design */
@media (max-width: 1024px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }
 
  .company-info,
  .menu,
  .social-links {
    width: 80%;
    text-align: center;
    margin-bottom: 1rem;
  }
 
  .footer-content .logo .logo-img {
    margin: 0 auto;
  }
}
 
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }
 
  .company-info,
  .menu,
  .social-links {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
 
  .footer-content .logo .logo-img {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
}
 
@media (max-width: 480px) {
  .footer {
    padding: 1rem;
    font-size: 2vh;
  }
 
  h4 {
    font-size: 1rem;
  }
 
  .footer-content .logo .logo-img {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }
 
  .company-info p .address {
    width: 90%;
  }
 
  .company-info p .social-link {
    padding-left: 40%;
  }
}