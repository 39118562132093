@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Reddit+Mono:wght@200..900&family=Roboto:wght@100;300;400;500;700;900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* outline: auto; */
}

.app {
    width: 100%;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif; /* Sets the primary font to Roboto */
    font-weight: 400;
    overflow-x: hidden;
}
